<template>
    <main>
        <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
        <page-header-compact>Nota de Enfermeria ( <span class="small"> {{nota.fecha}})</span></page-header-compact>
        <div class="container-xl px-4 mt-n15">
            <div class="card mt-2">
                <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
            </div>
            <div class="row gutters-sm">
                <div class="col-md-3 mb-3">
                    <div class="card mt-2 text-center ">
                        <div class="card-header">P.A.SISTOL / P.A.DIASTOL</div>
                        <div class="card-body">                           
                            <h6>
                                <i class="fa fa-stethoscope text-info fa-fw"></i>
                                 {{nota.pa_sistolica}} / {{nota.pa_diastolica}} <span class="text-danger">(mmHg)</span>
                            </h6>
                        </div>
                    </div>
                    <div class="card mt-2 text-center ">
                        <div class="card-header">PULSO</div>
                        <div class="card-body">
                            <h6>
                                <i class="fa fa-history text-success fa-fw"></i>
                                 {{nota.pulso}} <span class="text-danger">(lat/min)</span>
                            </h6>
                        </div>
                    </div>
                    <div class="card mt-2 text-center ">
                        <div class="card-header">PESO</div>
                        <div class="card-body">
                            <h6>
                                <i class="fa fa-weight text-primary fa-fw"></i>
                                {{nota.peso}} <span class="text-danger">(KG)</span>
                            </h6>
                        </div>
                    </div> 
                </div>
                <div class="col-md-3">
                    <div class="card mt-2 text-center ">
                        <div class="card-header">TEMPERATURA</div>
                        <div class="card-body">
                            <h6>
                                <i class="fa fa-thermometer text-danger fa-fw"></i>
                                 {{nota.temperatura}} <span class="text-danger">(°C)</span>
                            </h6>
                        </div>
                    </div>
                    <div class="card mt-2 text-center ">
                        <div class="card-header">RESPIRACION</div>
                        <div class="card-body">
                            <h6>
                                <i class="fa fa-lungs text-info fa-fw"></i>
                                 {{nota.respiracion}} <span class="text-danger">(r/m)</span>
                            </h6>
                        </div>
                    </div>
                    <div class="card mt-2 text-center ">
                        <div class="card-header">TALLA</div>
                        <div class="card-body">
                            <h6>
                                <i class="fa fa-ruler text-warning fa-fw"></i>
                                 {{nota.talla}} <span class="text-danger">(CM)</span>
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card mt-2">
                        <div class="card-header">Revisión por sistemas</div>
                        <div class="card-body">
                            <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between align-items-center" v-for="item in nota.notas_enfermeria_sistemas" :key="item.id">
                                    {{item.sistema.nombre}} 
                                    <span class="badge badge-info badge-pill" v-if="item.estado==='Normal'">{{item.estado}}</span>
                                    <span class="badge badge-danger  badge-pill" v-if="item.estado==='Anormal'">{{item.estado}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div class="col-md-12">
                    <div class="card mt-2">
                        <div class="card-header">Descripción</div>
                        <div class="card-body">
                            <label  class="col-sm-12 col-form-label"> 
                                {{nota.descripcion}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                
            </div>
                
        </div>
    </main>
</template>
<script>

import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
import notasEnfermeriaService from '../../../../services/notasEnfermeriaService';
import modalInformacionUsuario from "../../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../../base/informacionUsuario/cardInformacionUsuario";
import usuarioService from '../../../../services/usuarioService';
import Toast from '../../../../components/common/utilities/toast';

export default {
    components: {PageHeaderCompact,modalInformacionUsuario,cardInformacionUsuario},
    data(){
        return {
            nota: {},
            usuario: {},
           
        }
    },
    methods: {
        async cargarUsuario() {
            const response = await usuarioService.show(this.nota.id_usuario);
            this.usuario = response.data;
        },
        async cargarNota(id) {
            const response = await notasEnfermeriaService.show(id);
            this.nota = response.data;
        },
       
    },
    async created(){
        try {
            this.LoaderSpinnerShow();
            await this.cargarNota(this.$route.params.id); 
            await this.cargarUsuario();
            this.LoaderSpinnerHide();
        } catch (error) {
            console.error(error);
            this.LoaderSpinnerHide();
            Toast.fire({
                icon: 'error',
                title: 'Ocurrio un error al procesar la solicitud'
            });
        }
        
    }
}
</script>